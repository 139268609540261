import logo from './logo.svg';
import './App.css';

import { Router, Routes, Route, BrowserRouter } from 'react-router-dom';

import Layout from './components/Layout';
import Home from './components/Home';
import Quiz from './components/Quiz';
import Test1 from './components/Test1';
import TestsGiven from './components/TestsGiven';
import ApplicationState from './global/ApplicationState';
import Result from './components/Result';
import Result1 from './components/Result1';
import Login from './components/Login';
import Register from './components/Register';
import { useEffect } from 'react';
//import RegisterNow from './components/RegisterNow';



function App() {

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Prevent F5 key refresh
      if (event.key === 'F5') {
        event.preventDefault();
        console.log('F5 key press disabled');
      }
      
      // Prevent Ctrl + R refresh
      if (event.ctrlKey && (event.key === 'r' || event.key === 'R')) {
        event.preventDefault();
        console.log('Ctrl + R key combination disabled');
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  return (
    <ApplicationState>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='/quiz' element={<Quiz />} />
            <Route path='/startTest' element={<Test1 />} />
            <Route path='/testsGiven' element={<TestsGiven />} />
            <Route path='/result' element={<Result />} />
            <Route path='/result1' element={<Result1 />} />
            
            {/* <Route path='/register' element={<RegisterNow/>} /> */}
          </Route>
          <Route path='/login' element={<Login/>} />
            <Route path='/register' element={<Register/>} />
        </Routes>
      </BrowserRouter>
    </ApplicationState>

  );
}

export default App;
