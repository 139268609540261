import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatDate } from '../lib/DateFunctions'
import ApplicationContext from '../global/ApplicationContext'


function Result1() {
  const { burl, setUserName, currentTest } = useContext(ApplicationContext)
  const [questions, setQuestions] = useState(null)
  const [marks, setMarks] = useState(0);

  useEffect(() => {

    async function fetchData() {
      console.log("current test: ", currentTest.id)
      const url = `${burl}/api/test/getTestAnswers?id=${currentTest.id}`
      
      let response = await fetch(url, {
        headers: {
          "token": localStorage.getItem('token')
        }
      })
      try {
        let data = await response.json()
        let quesArray = JSON.parse(data.question)
        console.log("questions from server", quesArray)
        //questions = [{"q": "541 + 963","a": 1504},{"q": "175 - 177","a": -2},{"q": "109 - 624","a": -515 }]
        let answer = quesArray
        setQuestions(answer)

        //calculateTotalMarks(answer)

      } catch (err) {
        console.log(err)
      }
    }
    fetchData()



  }, [])


  const calculateTotalMarks = (answer) => {
    let q = answer.ques
    let totalMarks = 0;
    q.forEach((element, index) => {
      totalMarks += element.marks
    });

    setMarks(totalMarks)
  }


  return (
    <div className='container pt-3 pb-5 '>
      <div className='d-flex justify-content-center align-self-baseline'>
        <div><h1> Test Result </h1> </div>
      </div>
      <div className='d-flex' >
      <div className='ms-auto'><h3> {`Total Marks : ${ questions? questions.totalMarks:''}`} </h3> </div>
      </div>

      <div className='py-3'>

        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Question</th>
              <th scope="col">Expected <br /> Answer</th>
              <th scope="col">Your <br /> Answer</th>
              <th scope="col">Marks</th>
              <th scope="col">Result</th>
            </tr>
          </thead>
          <tbody>
            {
              questions ? questions.ques.map((val, index) => {
                return (<tr key={index + 1} >
                  <th scope="row">{index + 1}</th>
                  <td>{val.q}</td>
                  <td>{val.a}</td>
                  <td>{val.m}</td>
                  <td>{val.marks}</td>
                  <td>{(val.a === val.m) ? <i className="bi bi-patch-check-fill fs-3 text-success"></i> : <i className="bi bi-patch-exclamation-fill fs-3 text-danger"></i>}</td>

                </tr>)
              }) : ''
            }

            {/* <tr>
                            <th scope="row">1</th>
                            <td>Mark</td>
                            <td>Otto</td>
                            <td>@mdo</td>
                          </tr>
                          <tr>
                            <th scope="row">2</th>
                            <td>Jacob</td>
                            <td>Thornton</td>
                            <td>@fat</td>
                          </tr>
                          <tr>
                            <th scope="row">3</th>
                            <td colspan="2">Larry the Bird</td>
                            <td>@twitter</td>
                          </tr> */}
          </tbody>
        </table>

      </div>


    </div>
  )
}

export default Result1