import React,{useContext, useEffect} from 'react'
import { Outlet,Link,useNavigate } from 'react-router-dom'
import ApplicationContext from '../global/ApplicationContext'

function Layout() {
  const {burl,setUserName, userName} = useContext(ApplicationContext)

  const navigate = useNavigate();

  useEffect( ()=>{

    async function fetchData() {
      
      const url = `${burl}/api/user/getUsername`
      
      console.log('url is :',url)
      try {
        //let response = await fetch(url,{'credentials': 'include'})
        let response = await fetch(url,{
          headers:{
            "token": localStorage.getItem('token')
          }
        } )
        let data = await response.json()
        console.log("data.user=",data)
        console.log(response.status,typeof(response.status))

        if (!(response.status == 401)){
          
          setUserName(data.user)

        }else{
          setUserName('')
          navigate('/login')
        }
      }catch(err){
        alert(err.message)
      }
    }
    fetchData();


  },[])

  

    const actionclick = () => {
      console.log('clcik')
      localStorage.setItem('token',''); 
      //alert('done  ')
      navigate('/login')
    }


  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <Link to='/' className="navbar-brand">
          Maths Lab
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to='/' >
                Home
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link to='/testsGiven' className="nav-link" href="#">
                Previous Tests
              </Link>
            </li> */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Sign out
              </a>
              <ul className="dropdown-menu">
                <li>
                  <button className="dropdown-item" onClick={actionclick} >
                    Logout
                  </button>
                </li>
                
              </ul>
            </li>
            
          </ul>
          {userName?<ul className="navbar-nav  mb-2 mb-lg-0">
          
          <li className="nav-item">
              {userName}
            </li></ul>:
          <ul className="navbar-nav  mb-2 mb-lg-0">
          
              <li className="nav-item">
                  <Link to='/login' className="nav-link">
                    Login 
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link">
                    / 
                  </a>
                </li>
                <li className="nav-item">
                  <Link to='/register' className="nav-link">
                    Register 
                  </Link>
                </li>
          </ul>}
          
        </div>
      </div>
    </nav>

    <Outlet/>

    </>
  )
}

export default Layout