import React, { useEffect, useRef, useState, useContext } from 'react'
import ApplicationState from '../global/ApplicationState'
import ApplicationContext from '../global/ApplicationContext'
import {  useNavigate } from 'react-router-dom'
import Result from './Result'
import { getNumber } from '../lib/DateFunctions'

let ct = 0;

function Test1() {
  const Navigate = useNavigate()
  const { burl, currentTest, myAnswers, setMyAnswers } = useContext(ApplicationContext)


  const [counter, setCounter] = useState(3)

  const [animate, setAnimate] = useState(true)

  const inputElement = useRef()
  const butRef = useRef()

  const [questions, setQuestions] = useState(null)
  // answers = {
  //    questions = [{"q": "541 + 963","a": 1504,"m":1504,"timeTaken":4344 },{"q": "175 - 177","a": -2},{"q": "109 - 624","a": -515 }]
  //    currentQuestion = 1
  //    questionStarted = tick1
  // }



  useEffect(() => {


    async function fetchData() {
      console.log("current test: ", currentTest.id)
      const url = `${burl}/api/test/getTestQuestions?id=${currentTest.id}`
      console.log('in test.js url is :', url)
      let response = await fetch(url, {
        headers: {
          "token": localStorage.getItem('token')
        }
      })
      try {
        let data = await response.json()
        let quesArray = JSON.parse(data.question)
        console.log("questions from server", quesArray)
        //questions = [{"q": "541 + 963","a": 1504},{"q": "175 - 177","a": -2},{"q": "109 - 624","a": -515 }]
        let answer = {
          "ques": quesArray,
          currentQuestion: 1,
        }
        


        setQuestions(answer)
      } catch (err) {
        console.log(err)
      }
    }
    fetchData();



  }, [])

  const handleBlur = () => {

    


  }

  const nextQuestion = async () => {
    //update the answer object
    butRef.current.disabled = true


    let answer = { ...questions }
    answer.ques[answer.currentQuestion - 1].m = parseInt(inputElement.current.value) //expected error
    answer.ques[answer.currentQuestion - 1].timeTaken = Date.now() - answer.ques[answer.currentQuestion - 1].timeTaken
    answer.currentQuestion++

    
    

    if (answer.currentQuestion > 25) {
      // update server and show result
      console.log("question is berfore",answer)

      answer = calculateMarks(answer)
      let totalMarks = calculateTotalMarks(answer);
      answer.totalMarks = totalMarks
    
      console.log("question is",answer)
      
      console.log("Updating .................................")
      const url = `${burl}/api/test/setAnswer?id=${currentTest.id}`
      console.log('in test.js url is :', url)
      const bod = JSON.stringify(answer);
      let response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "token": localStorage.getItem('token')
        },
        body: bod
      })
      try {
        console.log(response.status)
        setMyAnswers(answer.ques)
        Navigate('/result1')
      } catch (err) {
        console.log(err)
      }



    } else { //go to next question.

      if (!(answer.ques[answer.currentQuestion-1 ].timeTaken)){
        (answer.ques[answer.currentQuestion -1].timeTaken) = Date.now() 
       }
       console.log('timetaken:', (answer.ques[answer.currentQuestion -1].timeTaken))

      setQuestions(answer)
      inputElement.current.value = ''
      inputElement.current.focus();
      butRef.current.disabled = false
    }

  }

  const calculateTotalMarks = (answer) => {
    let q = answer.ques
    let totalMarks = 0;
    q.forEach((element, index) => {
      totalMarks += element.marks
    });

    return totalMarks
  }


  // answers = {
  //    ques : [{"q": "541 + 963","a": 1504,"m":1504,"timeTaken":4344, "marks"= },{"q": "175 - 177","a": -2},{"q": "109 - 624","a": -515 }]
  //    currentQuestion : 1
  
  // }

 
  const calculateMarks = (ans) => {
    let answer = ans.ques

    answer.forEach(element => {
      let marks = 0
      if(element.a == getNumber(element.m)){ // if answer is correct
        marks = 1000
        if(element.timeTaken < 10000  ) marks += 10
        if(element.timeTaken < 9000  ) marks += 10
        if(element.timeTaken < 8000  ) marks += 10
        if(element.timeTaken < 7000  ) marks += 10
        if(element.timeTaken < 6000  ) marks += 10
        if(element.timeTaken < 5000  ) marks += 10
        if(element.timeTaken < 4000  ) marks += 10
        if(element.timeTaken < 3000  ) marks += 10
        if(element.timeTaken < 2000  ) marks += 10
        if(element.timeTaken < 1000  ) marks += 10
        if(element.timeTaken < 500  ) marks += 10
      }
      element.marks = marks
    });

    return ans
  }

  

  useEffect(() => {
    setTimeout(() => {
      if (counter > 0) {
        let c = counter - 1
        setCounter(c);
        //console.log("couter : ", c)
      }
      else {
        //console.log("inelse:", counter)
        //clearInterval(timer)
        setAnimate(false)
        if (!(questions.ques[questions.currentQuestion - 1 ].timeTaken)){
          (questions.ques[questions.currentQuestion -1 ].timeTaken) = Date.now() 
         }
         console.log('timetaken:', (questions.ques[questions.currentQuestion -1].timeTaken))

      }

    }, 1000)

  }, [counter])

  const navigate = useNavigate();
  const showResult = () => {
    navigate('/result')
  }



  ct++
  return (
    // questions.length === 0 ? <></> : (!myAnswers.find(item => item.id == currentTest.id)) ? <></> :
    //   (myAnswers.find(item => item.id == currentTest.id)?.data?.length == questions.length) ? <Result /> :
    <>
      <div className='container py-3' style={(!animate ? { display: 'block' } : { display: 'none' })}>
        <div className='d-flex justify-content-center'>
          <h3>Test :  {currentTest.id} </h3>

        </div>
        <div className='d-flex justify-content-center ' >
          <div style={{ width: 300 }} className='py-3'>
            <p>  Question  {questions? questions.currentQuestion:''} :   <span className='px-2'></span>  <strong> {questions ? questions.ques[questions.currentQuestion - 1].q : ''}</strong>  </p>
            {!animate ? (<input type='text' onKeyDown={(e) => {
              if (e.key === "Enter")
                nextQuestion();
              if (e.key === 'F5')
                e.preventDefault();
            }}
              //setting the start time in the timeTaken property as soon as the control has the focus.
              
              autoFocus ref={inputElement} className="form-control" />) : ''}

            <button ref={butRef} type="button" className="my-3 btn btn-primary" onClick={nextQuestion}>Submit</button>

          </div>
        </div>


      </div>

      <div style={(animate ? { display: 'block' } : { display: 'none' })}>
        <div className='w-100  d-flex justify-content-center align-items-center' style={{ marginTop: 150 }} >

          <h1>{counter}</h1>
        </div>

      </div>
    </>

  )
}

export default Test1
