

//dd,mm,mon,yyyy,yy,hr,min,se,ms
export function formatDate(dt, style) {
  console.log('dt is:',dt)

  let marr = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  let date = dt.getDate()
  date = date < 10 ? '0' + date : '' + date

  let month = dt.getMonth() + 1;
  let mon = marr[month];
  month = month < 10 ? '0' + month : '' + month

  let year = dt.getFullYear();
  let yr = year % 100

  let hr = dt.getHours();
  let mi = dt.getMinutes();
  let se = dt.getSeconds();



  style= style.replace('dd',date)
  style = style.replace('mm',month)
  style = style.replace('mon',mon)
  style = style.replace('yyyy',year)
  style =style.replace('yy',yr)
  style =style.replace('hr',hr)
  style =style.replace('mi',mi)
  style =style.replace('se',se)

  
  console.log(style)
  return style
}


export function addHours(dt,hrs){
  return new Date(dt.getTime()+ (hrs*60*60*1000))
}



//59 mins
//23 hours
//Yesterday
//6 days
//3 weeks
//11 months
//3 years
export function getDifference(d1,d2){
  let d1m = parseInt(Date.parse(d1))
  let d2m = parseInt(Date.parse(d2))

  return d2m-d1m


}


export function getNumber(text){
  let a = parseInt(text)
  if(isNaN(a)  ){
       return 0
  }else{
       return a
  }
  
}