import {  useState } from "react";
import ApplicationContext from "./ApplicationContext";


export function ApplicationState(props) {
  //alert(process.env.REACT_APP_MODE === '1')
  const [myAnswers, setMyAnswers] = useState()
  const [count, setCount] = useState(0)
  const [currentTest,setCurrentTest] = useState({id:0,name:''})
  const [userName,setUserName]=useState('')
  const [burl,setBUrl] = useState(`${(process.env.REACT_APP_MODE === '1')?process.env.REACT_APP_SERVER_BACKEND_URL:process.env.REACT_APP_DEBUG_BACKEND_URL}`)

  return (
    <ApplicationContext.Provider value={{burl,setBUrl, myAnswers, setMyAnswers, count, setCount, currentTest, setCurrentTest,userName,setUserName}}>
      {props.children}
    </ApplicationContext.Provider>
  )
}


export default ApplicationState