import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import ApplicationContext from '../global/ApplicationContext';

function Login() {
  
  const [loginError,setLoginError] = useState('') 
  const navigate = useNavigate();
  const { register, handleSubmit, formState } = useForm({ mode: 'onBlur' });
  const { errors } = formState;

  const {burl, setUserName} = useContext(ApplicationContext)

  useEffect(() => {
    async function isUserValid() {
      const response = await fetch(`${burl}/api/user/isAuth`, {
        headers: {
          "token": localStorage.getItem('token'),
          "Content-Type": "application/json",
          "Accept": "application/json"
        }
      })
      //const data = await response.json()
      if (response.status == 200) {
        navigate('/')
      }

    }
    isUserValid()


  }, [])

  const onSubmit = async (data) => {

    console.log(data.username)

    const bodyData = {
      "email": data.username,
      "password": data.password
    }
    const response = await fetch(`${burl}/api/user/login`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify(bodyData)
    })
    console.log(response);
    if (response.status == 200) {
      const t = await response.json()
      activateUser(t.name, t.token)
      
    }else{
      setLoginError('Invalid Credentials')
    }
  }


  function activateUser(name, token) {
    localStorage.setItem('token', token)
    console.log("username is :", name)
    setUserName(name)
    navigate('/')
  }

  const divStyles =
  {
    marginBottom: '10px',
    border: 'none',
    borderRadius: '5px',
    boxShadow: '0px 0 30px rgba(1, 41, 112, 0.3)'
  }

  return (
    <section className="section register min-vh-100 d-flex  py-3">
      <div className="container py-0">
        <div className="row justify-content-center py-0">
          <div className="col-lg-4 col-md-6 d-flex flex-column  justify-content-center">

            <div className="card mb-3" style={divStyles}>
              <div className="card-body">
                <div className="pt-4 pb-2">
                  <h5 className="card-title text-center pb-0 fs-4">
                    Login to Your Account
                  </h5>
                  <p className="text-center small">
                    Enter your username &amp; password to login
                  </p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="row g-3 needs-validation" noValidate="">
                  <div className="col-12">
                    <label htmlFor="yourUsername" className="form-label">
                      Username
                    </label>
                    <div className="input-group has-validation">
                      <span className="input-group-text" id="inputGroupPrepend">
                        @
                      </span>
                      <input
                        type="text"
                        name="username"
                        className="form-control"
                        id="yourUsername"
                        {...register('username', { required: "Please enter the email as the username" })}

                      />
                      {errors.username ? <div className="invalid-feedback d-block">
                        {errors.username.message}
                      </div> : <div className="invalid-feedback d-block text-white " >
                        |
                      </div>}

                    </div>
                  </div>
                  <div className="col-12">
                    <label htmlFor="yourPassword" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      id="yourPassword"
                      {...register('password', { required: "Password required" })}
                    />
                    {errors.password ? <div className="invalid-feedback d-block">
                      {errors.password.message}
                    </div> : <div className="invalid-feedback d-block text-white " >
                      |
                    </div>}
                  </div>
                  <div className="col-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="remember"
                        defaultValue="true"
                        id="rememberMe"
                      />
                      <label className="form-check-label" htmlFor="rememberMe">
                        Remember me
                      </label>
                    </div>
                    {loginError ? <div className="invalid-feedback d-block">
                        {loginError}
                      </div> : <div className="invalid-feedback d-block text-white " >
                        |
                      </div>}
                  </div>
                  <div className="col-12">
                    <button className="btn btn-primary w-100" type="submit">
                      Login
                    </button>
                  </div>
                  <div className="col-12">
                    <p className="small mb-0">
                      Don't have account?{" "}
                      <Link to="/register">Create an account</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

  )
}

export default Login