import React, { useContext } from 'react'
import '../App.css';
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom';
import ApplicationContext from '../global/ApplicationContext';

function Register() {
  const navigate = useNavigate()
  const { register, handleSubmit, formState } = useForm({ mode: 'onBlur' })
  const { errors } = formState

  const {burl, setUserName } = useContext(ApplicationContext)

  const onSubmit = async (data) => {
    //console.log(data)
    let bodyData = {
      name: data.name,
      email: data.email,
      password: data.password
    }
    const response = await fetch(`${burl}/api/user/signup`, {
      method: 'POST',
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify(bodyData)
    })
    console.log(response);
    const t = await response.json()
    activateUser(t.name, t.token)

  }

  function activateUser(name, token) {
    //localStorage.setItem('token', token)
    //console.log("username is :", name)
    //setUserName(name)
    navigate('/login')

  }

  return (
    <section className="section register min-vh-100 d-flex  py-4">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

            <div className="card ccard mb-3">
              <div className="card-body">
                <div className="pt-4 pb-2">
                  <h5 className="card-title text-center pb-0 fs-4">
                    Create an Account
                  </h5>
                  <p className="text-center small">
                    Enter your personal details to create account
                  </p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="row g-3 needs-validation" noValidate="">
                  <div className="col-12">
                    <label htmlFor="yourName" className="form-label">
                      Your Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="yourName"
                      {...register('name', { required: 'Name is Required' })}
                    />
                    {errors.name ? <div className="invalid-feedback d-block">{errors.name.message}</div>
                      : <div className="invalid-feedback  d-block text-white">|</div>
                    }
                  </div>


                  <div className="col-12">
                    <label htmlFor="yourEmail" className="form-label">
                      Your Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      id="yourEmail"
                      {...register('email', {
                        required: 'Email is Required',
                        pattern: {
                          value: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                          message: "Invalid Email format.."
                        }
                      })}
                    />
                    <div className="invalid-feedback">
                      Please enter a valid Email adddress!
                    </div>
                  </div>

                  <div className="col-12">
                    <label htmlFor="yourPassword" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      id="yourPassword"
                      {...register('password', { required: 'Password is Required' })}
                    />
                    <div className="invalid-feedback">
                      Please enter your password!
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="terms"
                        type="checkbox"
                        defaultValue=""
                        id="acceptTerms"

                      />
                      <label className="form-check-label" htmlFor="acceptTerms">
                        I agree and accept the <a href="#">terms and conditions</a>
                      </label>
                      <div className="invalid-feedback">
                        You must agree before submitting.
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <button className="btn btn-primary w-100" type="submit">
                      Create Account
                    </button>
                  </div>
                  <div className="col-12">
                    <p className="small mb-0">
                      Already have an account? <Link to="/login">Log in</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

  )
}

export default Register