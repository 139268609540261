import React, { useContext, useState, useEffect } from 'react'
import {useNavigate } from 'react-router-dom'
import {formatDate } from '../lib/DateFunctions'
import ApplicationContext from '../global/ApplicationContext'


function Result() {

  const {myAnswers,currentTest} = useContext(ApplicationContext)
  
  return (
    <div className='container pt-3 pb-5 '>
      <div className='d-flex justify-content-center'>
        <h1> Test Result </h1>

      </div>

      <div className='py-3'>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Question</th>
              <th scope="col">Expected <br/> Answer</th>
              <th scope="col">Your <br/> Answer</th>
              <th scope="col">Result</th>
            </tr>
          </thead>
          <tbody>
            {
              myAnswers.find(item => item.id == currentTest.id).data.map((val,index)=>{
                  return(<tr key={index+1} >
                    <th scope="row">{index+1}</th>
                      <td>{val.question}</td>
                      <td>{ val.answer}</td>
                      <td>{val.myanswer}</td>
                      <td>{(val.answer===val.myanswer)?<i className="bi bi-patch-check-fill fs-3 text-success"></i>:<i className="bi bi-patch-exclamation-fill fs-3 text-danger"></i>}</td>

                  </tr>)
              })
            }

            {/* <tr>
                            <th scope="row">1</th>
                            <td>Mark</td>
                            <td>Otto</td>
                            <td>@mdo</td>
                          </tr>
                          <tr>
                            <th scope="row">2</th>
                            <td>Jacob</td>
                            <td>Thornton</td>
                            <td>@fat</td>
                          </tr>
                          <tr>
                            <th scope="row">3</th>
                            <td colspan="2">Larry the Bird</td>
                            <td>@twitter</td>
                          </tr> */}
          </tbody>
        </table>

      </div>


    </div>
  )
}

export default Result