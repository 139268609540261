import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { addHours, formatDate } from '../lib/DateFunctions'
import ApplicationContext from '../global/ApplicationContext'

let count = 0;

function Home() {

  //const variable1 = process.env.REACT_APP_MODE
  let { burl, setBUrl, currentTest, setCurrentTest, setUserName, userName } = useContext(ApplicationContext)

  const [testTable, setTestTable] = useState([])
  const [solvedtestTable, setSolvedTestTable] = useState([])
  const navigate = useNavigate();

  const startTest = (event) => {

    setCurrentTest(JSON.parse(event.target.value))
    navigate('/startTest')
  }

  const viewResult = (event) => {
    //alert(parseInt(event.target.value))
    setCurrentTest(JSON.parse(event.target.value))
    navigate('/result1')
  }

  useEffect(() => {
    async function fetchData() {

      const url = `${burl}/api/test/getAllQuiz`

      console.log('url is :', url)
      try {
        //let response = await fetch(url,{'credentials': 'include'})
        let response = await fetch(url, {
          headers: {
            "token": localStorage.getItem('token')
          }
        })
        let data = await response.json()
        console.log(data)
        console.log(response.status, typeof (response.status))
        if (!(response.status == 401)) {
          setTestTable(data)

        } else {
          setUserName('')
          navigate('/login')
        }
      } catch (err) {
        alert(err.message)
      }
    }

    async function fetchData1() {

      const url = `${burl}/api/test/getGivenQuiz`

      console.log('url is :', url)
      try {
        //let response = await fetch(url,{'credentials': 'include'})
        let response = await fetch(url, {
          headers: {
            "token": localStorage.getItem('token')
          }
        })
        let data = await response.json()
        console.log(data)
        console.log(response.status, typeof (response.status))
        if (!(response.status == 401)) {
          setSolvedTestTable(data)
        } else {
          setUserName('')
          navigate('/login')
        }
      } catch (err) {
        alert(err.message)
      }
    }
    fetchData();
    fetchData1();

  }, [])

  count++;
  return (
    <>
    { 
      (userName) ?
      <div className='container pt-3 pb-5 '>
        <div className='d-flex justify-content-center'>
          <h1> Today's Quiz   </h1>

        </div>

        <div className='py-3'>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Test Name</th>
                <th scope="col">Start Test</th>
              </tr>
            </thead>
            <tbody>
              {
                testTable.map((val, index) => {
                  return (<tr key={index + 1} >
                    <th scope="row">{val.Sr}</th>
                    <td>{val.que_type}</td>
                    <td><button value={JSON.stringify({ id: val.Sr })} type="button" className="btn btn-primary" onClick={startTest} >Start Test</button></td>

                  </tr>)
                })
              }
            </tbody>
          </table>
        </div>
        <div>
          <hr />
        </div>

        <div className='d-flex justify-content-center mt-5 pt-5'>
          <h1> View Result    </h1>

        </div>

        <div className='py-3'>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Test Name</th>
                <th scope="col">View Test</th>
                <th scope="col">Score</th>
              </tr>
            </thead>
            <tbody>
              {
                solvedtestTable.map((val, index) => {
                  return (<tr key={index + 1} >
                    <th scope="row">{val.Sr}</th>
                    <td>{val.que_type}</td>
                    <td><button value={JSON.stringify({ id: val.Sr })} type="button" className="btn btn-primary" onClick={viewResult} >View Result</button></td>
                    <td>{val.marks}</td>

                  </tr>)
                })
              }

            </tbody>
          </table>


          {/* <tr>
                            <th scope="row">1</th>
                            <td>Mark</td>
                            <td>Otto</td>
                            <td>@mdo</td>
                          </tr>
                          <tr>
                            <th scope="row">2</th>
                            <td>Jacob</td>
                            <td>Thornton</td>
                            <td>@fat</td>
                          </tr>
                          <tr>
                            <th scope="row">3</th>
                            <td colspan="2">Larry the Bird</td>
                            <td>@twitter</td>
                          </tr> */}



        </div>


      </div>
      :
      <></>
    }
    </>
  )


}

export default Home